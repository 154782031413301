import React, { Fragment } from "react";
import {
  ADD_CNI_TO,
  ADD_LICENCE_TO,
  ADD_LICENCE_TO_THE_REGISTRY,
  ADD_LICENCE_TO_THE_TAXI,
  CREATE_DRIVER,
  CREATE_TAXI,
  EDIT_CLIENT,
  EDIT_DRIVER,
  EDIT_TAXI,
} from "Constant";

import EditItem from "Components/EditItemWithZod";
import AddItem from "Components/AddItemWithZod";

import { CREATE_TAXI_DRIVER } from "./data/users";
import { CREATE_ITEMS_TAXI } from "./data/Taxis";
import { EDIT_ITEMS_CLIENT } from "./data/data";

import EditDriver from "./EditDriver";

import EditTaxi from "./EditTaxi";

import ModalApp from "Components/ModalApp";
import UploadDoc from "./UploadDoc";

const FormPupUp = ({ clientCode }) => {
  return (
    <Fragment>
      <ModalApp type="editClient" title={EDIT_CLIENT}>
        <EditItem itemsUpdate={EDIT_ITEMS_CLIENT} newVal={{ active: true }} />
      </ModalApp>

      <ModalApp type="editDriver" title={EDIT_DRIVER}>
        <EditDriver itemsUpdate={CREATE_TAXI_DRIVER(clientCode)} />
      </ModalApp>

      <ModalApp type="editTaxi" title={EDIT_TAXI}>
        <EditTaxi itemsUpdate={CREATE_ITEMS_TAXI} />
      </ModalApp>

      <ModalApp type="createDriver" title={CREATE_DRIVER}>
        <AddItem
          addURL={`/back/client/${clientCode}/user`}
          items={CREATE_TAXI_DRIVER(clientCode)}
        />
      </ModalApp>

      <ModalApp type="createTaxi" title={CREATE_TAXI}>
        <AddItem
          addURL={`/back/client/${clientCode}/taxi`}
          items={CREATE_ITEMS_TAXI}
        />
      </ModalApp>

      <ModalApp type="editTaxiLicence" title={ADD_LICENCE_TO_THE_TAXI}>
        <UploadDoc />
      </ModalApp>

      <ModalApp type="editTaxiRegistry" title={ADD_LICENCE_TO_THE_REGISTRY}>
        <UploadDoc />
      </ModalApp>

      <ModalApp type="editClientLicence" title={ADD_LICENCE_TO}>
        <UploadDoc />
      </ModalApp>

      <ModalApp type="editClientCni" title={ADD_CNI_TO}>
        <UploadDoc />
      </ModalApp>
    </Fragment>
  );
};

export default FormPupUp;
