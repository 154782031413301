import {
  Accordion,
  Avatar,
  Center,
  Group,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconBrandProducthunt } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import {
  CREATED_AT,
  MODEL,
  PRODUCT,
  QUANTITY,
  SERIAL_NUMBER,
  TAXI_INFO,
  TOTAL,
} from "Constant";

const ProductsComp = ({ data }) => {
  return (
    <Fragment>
      {data.map((prod, i) => {
        const { total, category, model, quantity, serials, created_at } = prod;

        const title = `${category}`;

        const requiredKeys = [
          { title: MODEL, key: model },
          { title: QUANTITY, key: quantity },

          { title: SERIAL_NUMBER, key: serials },
          { title: CREATED_AT, key: created_at },
        ];
        return (
          <Fragment key={i}>
            <Accordion
              chevronPosition="left"
              variant="contained"
              defaultValue={["client"]}
            >
              <Accordion.Item value={"client"} key={0}>
                <Center>
                  <Accordion.Control>
                    <Group wrap="nowrap">
                      <Avatar radius="xl" size="lg" color="orange">
                        {<IconBrandProducthunt color="black" />}
                      </Avatar>

                      <Space w="md" />

                      <div>
                        <Text size="xl" fw={700}>
                          {title}
                        </Text>
                        <Text size="sm" c="dimmed" fw={400}>
                          {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                        </Text>
                      </div>
                    </Group>
                  </Accordion.Control>
                  {/* <TaxiSettings data={data} /> */}
                  <Space w="lg" />
                </Center>

                <Accordion.Panel>
                  <Space h="md" />
                  <Paper shadow="xs" withBorder p="xl">
                    <SimpleTable requiredKeys={requiredKeys} />
                  </Paper>
                  <Space h="md" />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default ProductsComp;
