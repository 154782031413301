import React, { Fragment } from "react";
import { IconSettings } from "@tabler/icons-react";
import {
  Accordion,
  ActionIcon,
  Avatar,
  Center,
  Divider,
  Grid,
  Group,
  Image,
  Indicator,
  Paper,
  Space,
  Text,
  Tooltip,
} from "@mantine/core";

import {
  CLIENT_INFO,
  FULL_NAME,
  COMPANY,
  PHONE,
  REGISRTY,
  ADDRESS,
  CITY,
  ZIP_CODE,
  ACTIVE,
  PROFESSIONAL_CARD_INFO,
  SETTINGS,
  PRO_CARD_GOT_AT,
  PRO_CARD_EXPIRE_AT,
  DOCUMENTS,
  THERE_IS_NO_DATA_FOUND,
  LICENCE,
} from "Constant";

import { ClientSettingsBtn } from "./SettingsBtn";
import SimpleTable from "ComponentsMantine/SimpleTable";
import Translate from "Components/Translate";

const ClientInfo = ({ data }) => {
  const fullName = `${data.firstname} ${data.lastname}`;

  const {
    company,
    phone,
    registry,
    address,
    city,
    zip_code,
    pro_card,
    pro_card_got_at,
    pro_card_expire_at,
    client_active,
    image,
    client_cni,
    client_licence,
  } = data;

  const clientPersonelData = [
    { title: FULL_NAME, key: fullName },
    { title: COMPANY, key: company },

    { title: PHONE, key: phone },
    { title: REGISRTY, key: registry },
    { title: ADDRESS, key: address },
    { title: CITY, key: city },
    { title: ZIP_CODE, key: zip_code },
    {
      title: "Pro card",
      key: pro_card,
    },
    {
      title: PRO_CARD_GOT_AT,
      key: pro_card_got_at,
    },
    {
      title: PRO_CARD_EXPIRE_AT,
      key: pro_card_expire_at,
    },
  ];

  const indicatorProps = client_active
    ? { color: "green", label: "Active" }
    : { color: "red", label: "Not active" };

  const title = <Text fw={700}> {fullName} </Text>;

  return (
    <Fragment>
      <Accordion
        chevronPosition="left"
        variant="contained"
        defaultValue={["client"]}
      >
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Indicator
                  // inline
                  // label={indicatorProps.label}
                  color={indicatorProps.color}
                  // size={16}
                >
                  <Avatar radius="xl" src={image} size="lg" color="orange" />
                </Indicator>

                <Space w="md" />

                <div>
                  <Text size="xl">{title}</Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            {/* <ActionIcon size="lg" variant="subtle" color="gray"> */}
            <ClientSettingsBtn data={data} />

            {/* </ActionIcon> */}

            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              <Divider
                my="xs"
                label={<Text fw={700}> {CLIENT_INFO} </Text>}
                labelPosition="center"
              />
              <SimpleTable requiredKeys={clientPersonelData} />
              <Divider
                my="xs"
                label={
                  <Text fw={700}>
                    <Translate>{DOCUMENTS}</Translate>
                  </Text>
                }
                labelPosition="center"
              />

              <Grid grow justify="center" align="flex-start">
                <Grid.Col span={6}>
                  <Text fw={700}>
                    <Translate>{REGISRTY}</Translate>
                  </Text>
                  {client_cni ? (
                    <Image src={client_cni} downloadable preview height="255" />
                  ) : (
                    <Translate>{THERE_IS_NO_DATA_FOUND}</Translate>
                  )}
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text fw={700}>
                    <Translate>{LICENCE}</Translate>
                  </Text>

                  {client_licence ? (
                    <Image
                      src={client_licence}
                      downloadable
                      preview
                      height="255"
                    />
                  ) : (
                    <Translate>{THERE_IS_NO_DATA_FOUND}</Translate>
                  )}
                </Grid.Col>
              </Grid>
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default ClientInfo;
