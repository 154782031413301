import { Fragment } from "react";

import {
  Group,
  Avatar,
  Text,
  Accordion,
  Badge,
  Center,
  Indicator,
  Space,
  Paper,
  Divider,
  Grid,
} from "@mantine/core";

import { IconCar } from "@tabler/icons-react";

import { Image } from "primereact/image";

import HeaderTab from "./HeaderTab";
import ListOfTaxiData from "./ListOfTaxiData";
import { CreateTaxi, TaxisSettingsBtn } from "./SettingsBtn";

import {
  DOCUMENTS,
  LICENCE,
  REGISTRATION,
  THERE_IS_NO_DATA_FOUND,
} from "Constant";
import Translate from "Components/Translate";

function TaxisInfo({ data }) {
  return (
    <Fragment>
      <HeaderTab tab={[<CreateTaxi />]} />

      <Accordion chevronPosition="left" variant="contained">
        {data.map((elem, i) => {
          const id = i.toString();
          const { taxi_serial_number, active_taxi, taxi_cg, taxi_licence } =
            elem;
          const taxiTitle = `${elem.brand_taxi} / ${elem.model_taxi} / ${elem.taxi_serial_number}`;

          const title = (
            <Text fw={700}>
              <Badge variant="dot" color="gray">
                <Text size="lg">{taxiTitle}</Text>
              </Badge>
            </Text>
          );

          const indicatorProps = active_taxi
            ? { color: "green", label: "Active" }
            : { color: "red", label: "Not active" };

          return (
            <Accordion.Item value={id} key={taxi_serial_number}>
              <Center>
                <Accordion.Control>
                  <Group wrap="nowrap">
                    <Indicator inline color={indicatorProps.color} size={16}>
                      <Avatar radius="xl" size="lg" color="orange">
                        <IconCar color="black" />
                      </Avatar>
                    </Indicator>

                    <Space w="md" />

                    <div>
                      <Text size="xl">{title}</Text>
                      <Text size="sm" color="dimmed" fw={400}>
                        {/* Additional details here */}
                      </Text>
                    </div>
                  </Group>
                </Accordion.Control>

                <TaxisSettingsBtn data={elem} />

                <Space w="lg" />
              </Center>

              <Accordion.Panel>
                <Paper shadow="xs" withBorder p="xl">
                  <ListOfTaxiData taxiDetails={elem} />

                  <Divider
                    my="xs"
                    label={
                      <Text fw={700}>
                        <Translate>{DOCUMENTS}</Translate>
                      </Text>
                    }
                    labelPosition="center"
                  />

                  <Grid grow justify="center" align="flex-start">
                    <Grid.Col span={6}>
                      <Text fw={700}>
                        <Translate>{LICENCE}</Translate>
                      </Text>
                      {taxi_cg ? (
                        <Image
                          src={taxi_cg}
                          downloadable
                          preview
                          height="255"
                        />
                      ) : (
                        <Translate>{THERE_IS_NO_DATA_FOUND}</Translate>
                      )}
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text fw={700}>
                        <Translate>{REGISTRATION}</Translate>
                      </Text>

                      {taxi_licence ? (
                        <Image
                          src={taxi_licence}
                          downloadable
                          preview
                          height="255"
                        />
                      ) : (
                        <Translate>{THERE_IS_NO_DATA_FOUND}</Translate>
                      )}
                    </Grid.Col>
                  </Grid>
                </Paper>
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Fragment>
  );
}

export default TaxisInfo;
