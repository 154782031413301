import { Text } from "@mantine/core";
import { TOTAL, TTC } from "Constant";
import { calculateProduct } from "helper/estimateFx";

export const testStatus = (status) => {
  if (status === "canceled") {
    return false;
  }
  if (status === "draft") {
    return true;
  }
  if (status === "confirmed") {
    return false;
  }
  if (status === "paid") {
    return false;
  }
  if (status === "created") {
    return true;
  }
  if (status === "ready") {
    return false;
  }

  return true;
};

export const getRowRes = (data) => {
  try {
    const res = data.data.results.data;
    return res;
  } catch {
    return {};
  }
};

export const columnsProd = [
  {
    header: "Catégorie",
    field: (rowData) => {
      const { category } = rowData;

      return getCategoryLabel(category);
    },
  },
  // { header: "modèle", field: "model" },
  { header: "modèle", field: "model_label" },
  {
    header: "Description",
    field: (rowData) => {
      const { description, discount } = rowData;
      if (discount == "100.000" || description == "Gratuit")
        return (
          <Text fw={800} c="teal.5">
            {description}
          </Text>
        );

      return description;
    },
  },
  { header: "Quantité", field: "quantity" },
  { header: "Réduction %", field: "discount" },
  { header: "Prix ht", field: "unit_price" },
  { header: "TVA %", field: "vat" },
  {
    header: TTC,
    field: (rowData) => {
      const { unit_price, vat } = rowData;

      return calculateProduct(unit_price, vat);
    },
  },
];

export const columnsServ = [
  { header: "Service", field: "service_label" },
  { header: "Description", field: "description" },
  { header: "Mois", field: "quantity" },
  { header: "Réduction %", field: "discount" },
  { header: "Prix ht", field: "unit_price" },
  { header: "TVA %", field: "vat" },
  {
    header: TTC,
    field: (rowData) => {
      const { unit_price, vat } = rowData;

      return calculateProduct(unit_price, vat);
    },
  },
];

export const estimateDetailsColumns = [
  { header: "Dépôt", field: "deposit" },
  { header: "Montant à échloner", field: "remainingAmount" },
  { header: "Échéancier", field: "timescale" },
];

const getCategoryLabel = (category) => {
  const obj = {
    printer: "Imprimante thermique",
    box: "Boîtier",
    car: "Véhicule",
    luminous: "Lumineux",
    sensor: "Capteur",
    badge: "Badge",
    taximeter: "Taximètre",
    stylus: "Stylet",
    bracket: "Support",
    sunshield: "Visière",
    obd_adapter: "Adaptateur obd",
    sos_button: "Bouton SOS",
    stop_car: "Commitateur relais",
    light_relay: "Relais lumineux",
  };

  return obj[category] ? obj[category] : category;
};
